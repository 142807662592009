
/* Imports */
import { Component, OnInit, ViewChildren, Input, Output, QueryList, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import {
	AuthenticationService,
	AlertService,
	OrganizationService,
	ProjectService,
	UserService,
	TitleService,
	PermissionService,
	UtilsService
} from '@shared/services';
import { Organization, USERROLES, Alert } from '@shared/models';
import { MatDialog } from '@angular/material/dialog';
import { RenameModal } from '../../rename';
import { ConfirmationModal } from 'src/app/components';
import { analyticsLayer } from '@shared/analyticsLayer';
import { byId } from '@shared/services';
import { isNil } from "lodash";

enum LoadingState {
	idle,
	loading,
	error,
	complete,
}

interface loadingStates {
	project: LoadingState;
	invitation: LoadingState;
}

@Component({
	selector: 'app-account-teams',
	templateUrl: './teams.component.html',
	styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

	@Input('user') user: any;
	@Input('orgList') orgList: Array<any>;
	@Output('updateList') updateList: EventEmitter<boolean> = new EventEmitter();
	@Output() closeModal: EventEmitter<boolean> = new EventEmitter();
	@ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;

	public createDisplay: boolean = false;

	public teamForm: FormGroup;
	public permissionList: Array<any> = [];
	public isMobile: boolean = false;
	public promiseStates: loadingStates = { project: LoadingState.idle, invitation: LoadingState.idle };
	public signedInUserPermission = {};
	public currentUserPermission;

	constructor(
		public dialog: MatDialog,
		private _dialog: MatDialog,
		private _authService: AuthenticationService,
		private _alertService: AlertService,
		private _permissionService: PermissionService,
		private _orgService: OrganizationService,
		private _projectService: ProjectService,
		private _userService: UserService,
		private _formBuilder: FormBuilder,
		private _cdr: ChangeDetectorRef,
		private _titleService: TitleService,
		private _utilsService: UtilsService
	) {
		this.createDisplay = false;
		this.teamForm = this.createTeamForm();
		this.permissionList = this._permissionService.getPermissionList();

		this.isMobile = this._utilsService.getIsMobile();

		this._utilsService.getScreenResize().subscribe((isMobile) => {
			this.isMobile = isMobile;
		})
	}

	ngOnInit() {

		this._titleService.setTitle('Teams & members');
		analyticsLayer.trackPage();

		if (!(this.orgList && this.orgList.length)) {
			this.createDisplay = true;
		}

		this.user = this._authService.user;
		this.setup();

	}	// End-of ngOnInit

	setup(): void {
		this.orgList.forEach(org => {
			this.setupOrg(org);
			this.orderDefaultOrg();
			this.getOrgLicenseSeats(org);
		});
	}

	setupOrg(org): void {

		org.users = this.sortAndRankUsers(org.users);

		this._projectService.getList(org).then(projList => {
			org.projects = projList.filter(proj => (proj.trash === 0 && proj.organization_id === org.id));
			this.promiseStates.project = LoadingState.complete;
			this._cdr.detectChanges(); // Change detection for the project list length
		}).catch(err => {
			console.error(err);
			this.promiseStates.project = LoadingState.error;
		});

		this._orgService.getInvitationList(org.id).then(inviteList => {
			inviteList.forEach(invite => {
				invite.created_at = new Date(invite.created_at);
				invite.updated_at = new Date(invite.updated_at);
			})
			org.invitations = inviteList;
			this.promiseStates.invitation = LoadingState.complete;
			this._cdr.detectChanges(); // Change detection for the invites
		}).catch(err => {
			console.error(err);
			this.promiseStates.invitation = LoadingState.error;
		});

		this._permissionService.getUserPermissions(org, this.user.id).then(rtnPermission => {
			this.signedInUserPermission[org.id] = rtnPermission;
		})

		this._cdr.detectChanges();
	}

	determinePromiseStatesFinished = (promiseStates) => Object.values(promiseStates).every(state => state === LoadingState.complete || state === LoadingState.error);

	sortAndRankUsers(orgUsers: Array<any>): Array<any> {
		if (orgUsers?.length) {
			const auth = this.getRole(orgUsers.find(byId(this.user.id)).role).rank;
			// Sort and place user in first position
			orgUsers.sort((a, b) =>
				a.id === this.user.id
					? -1
					: b.id === this.user.id
						? 1
						: a.first_name.localeCompare(b.first_name));

			//Always run refresh or setup functions to trigger this code... this disables edit/remove members
			//based on the active user's permission level.
			orgUsers.forEach(u => {
				if(typeof u.role === 'string') {
					u.role = this.getRole(u.role);
				}

				if (this.user.id === u.id) {
					this.currentUserPermission = u.role.value;
				}

				u.disabled = (u.role.value === 'admin') && (this.currentUserPermission === 'admin') ? false : u.role.rank >= auth;
				if(!u.disabled && orgUsers.length <= 1)
					u.disabled = true;
			});
		}
		return orgUsers;
	}

	toggleCreateTeam(): void {
		this.createDisplay = !this.createDisplay;
	}

	createTeamForm(inData?: Organization): FormGroup {

		let d = inData ? inData : new Organization();
		let formConfig = {
			name: ['', Validators.required],
		};
		return this._formBuilder.group(formConfig);

	}	// End-of createProjectForm

	createMemberForm(inData?: Organization): FormGroup {

		let d = inData ? inData : new Organization();
		let formConfig = {
			newMember: ['', Validators.required],
		};
		let teamForm = this._formBuilder.group(formConfig);
		return teamForm;

	}	// End-of createProjectForm

	onSubmit(): void {
		let org = new Organization();
		org.name = this.teamForm.value.name;
		this.createTeam(org);
	}

	createTeam(org): void {
		this._orgService
			.create(org)
			.then(data => {
				this.updateList.emit(true);
				this.createDisplay = false;
				this.refreshOrgList();
			}, error => {
				console.error(error);
			});
	}

	getRole(role): any {
		if (role.value) {
			role = role.value;
		}

		let ind = this.permissionList.findIndex((x) => {
			return x.value === role;
		});

		if (ind >= 0) {
			return this.permissionList[ind];
		} else {
			return this.permissionList[this.permissionList.length - 1];
		}
	}

	checkEmail(email): boolean {
		return (/\S+@\S+\.\S+/.test(email));
	}

	removeUserFromTeam(org, user): void {

		let confirmOptions = {
			title: `Remove ${user.first_name}?`,
			text: `Are you sure you wish to remove ${user.first_name} ${user.last_name} from ${org.name}?`,
			buttonText: `Remove from team`
		}

		//let confirmString: string = "Are you sure you want to remove the card ending in " + card.last4 + "?";
		let dialogRef = this._dialog.open(ConfirmationModal, { data: confirmOptions });
		dialogRef.afterClosed().subscribe(rtn => {
			if (rtn) {
				this._orgService.removeUser(org.id, user.id).then(rtn => {
					this._orgService.getList().then(rtn => {
						this._alertService.success(new Alert('Removed ' + user.first_name + ' from ' + org.name));
						this.orgList = rtn;
						this.refreshOrgList();
					}, err => {
						console.error(err);
						this._alertService.error(new Alert('Error removing' + user.first_name + ' from ' + org.name));
					});
				}, err => {
					console.error(err);
				});
			}
		});

	}

	deleteTeam(org: Organization): void {
		if (org.subscribed && !org.subscription?.cancel_at_period_end) {
			this.cannotDeletePrompt();
		} else {
			const confirmOptions = {
				title: `Delete ${org.name}?`,
				text: `Are you sure you wish to delete ${org.name}? This will delete the team for all members and cannot be undone.`,
				buttonText: `Delete`
			}

			const dialogRef = this._dialog.open(ConfirmationModal, { data: confirmOptions });
			dialogRef.afterClosed().subscribe(rtn => {
				if (rtn) {
					this._orgService.remove(org).then(rtn => {
						this.refreshOrgList();
						this._alertService.success(new Alert(`${org.name} has been deleted.`));
					}, err => {
						this._alertService.error(new Alert(`${org.name} failed to be deleted.`));
						console.error(err);
					});
				}
			});
		}

	}

	cannotDeletePrompt() {
		const issueOptions = {
			title: "Active Subscription",
			text: `Your team still has an active subscription, please cancel the subscription before deleting your team.`,
			buttonText: `Ok`,
			showCancel: false
		}
		this._dialog.open(ConfirmationModal, { data: issueOptions });
	}

	updateUserRole(e, org, user): void {
		const userRole = e.value.value;


		if (!isNil(userRole)) {
			this._orgService.updateUser(org.id, user.id, USERROLES[userRole]).then(rtn => {
				this.setupOrg(org);
				this._alertService.success(new Alert(`Changed ${user.first_name}'s role for ${org.name}.`));
			}, err => {
				console.error;
				this._alertService.error(new Alert(`Error changing ${user.first_name}'s role for ${org.name}.`));
			});
		} else {
			this._alertService.notify("Something went wrong while updating the user's role!", 'error');
			console.error("Something went wrong while updating the user's role!");
		}
	}

	removeInviteFromTeam(org, invite) {
		this._orgService.deleteInvitation(org.id, invite.id).then(rtnData => {
			this.setupOrg(org);
			this._alertService.success(new Alert('Invitation Deleted!'));
		})
	}

	openRenameModal(org): void {
		let dialogRef = this.dialog.open(RenameModal, { data: org });
		dialogRef.afterClosed().subscribe(rtn => {

			if (rtn) {
				org.name = rtn.name;
				this._orgService.update(org).then(rtn => {
					this.setupOrg(org);
					this._alertService.success(new Alert('Team successfully updated!'));
				}, err => {
					console.error(err);
					this._alertService.error(new Alert('Error renaming team!'));
				});

				this.refreshOrgList();
			}
		});

	}	// End-of openRenameModal

	setUserDefaultOrg(org: Organization): void {
		let u = this.user;
		u.default_organization_id = org.id;
		this._userService.update(u).then(rtn => {
			this._cdr.detectChanges();
		}, err => {
			console.error(err);
		});
		this.orderDefaultOrg();
	}

	orderDefaultOrg(): void {
		this.orgList = this.orgList.reduce((acc, element) => {
			if (element.id == this.user.default_organization_id) {
				return [element, ...acc];
			}
			return [...acc, element];
		}, []);
	}

	refreshOrgList(): void {
		this.updateList.emit(true);
		this._orgService.getList().then(rtn => {
			this.orgList = rtn.filter(x => x.active);
			this.setup();
		}, err => {
			console.error(err);
		});
	}

	handleBack(org = null, type: string = 'create'): void {
		if (org && type == 'add') {
			this.refreshOrgList();
		} else if (this.createDisplay) {
			this.teamForm.setValue({ 'name': '' });
			this.createDisplay = !this.createDisplay;
		}
	}

	clickedRight(e, i): void {
		e.preventDefault();
		this.menuTriggers.toArray()[i]._handleClick(e);
	}

	userHasOrgPermission(org, permission): boolean {
		return this.signedInUserPermission[org.id]?.[permission] ?? false;
	}

	canRemoveUserFromOrg(org, orgUser): boolean {
		return orgUser.id != this.user.id && this.userHasOrgPermission(org, 'admin')
	}

	getOrgLicenseSeats(org) {
		if(org.license_info) {
			org.license_info.current_seats = {
				admin: 0,
				process: 0,
				reader: 0
			};
			org.users?.forEach((user) => {
				org.license_info.current_seats[user.role.value] += 1;
			});
		}
	}

}	// End-of class TestComponentgetNumberInvitations
